import React, { Component } from "react";
import { connect } from 'react-redux';
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import Leaflet from 'leaflet'

const mapIcon = Leaflet.icon({
    iconUrl:  '/assets/map-icon-marker.png',
    iconSize: [48, 48],
});

class CommunityMap extends Component {

    render() {
        const { community, height, width } = this.props;

        if (!community) {
            return <div>Loading...</div>;
        }

        const { latitude, longitude, comm_code, name, sector, class: className } = community;

        console.log("latitude => " + latitude);
        console.log("longitude => " + longitude);

        return (
            <MapContainer
                center={[latitude, longitude]}
                zoom={12}
                scrollWheelZoom={false}
                style={{ height, width, zIndex: '0' }}
            >
                <TileLayer
                    attribution='YYC Communities &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[latitude, longitude]} icon={mapIcon}>
                    <Popup>
                        Community Code: <strong>{comm_code}</strong><br />
                        Community Name: <strong>{name}</strong><br />
                        Sector: <strong>{sector}</strong><br />
                        Class: <strong>{className}</strong>
                    </Popup>
                </Marker>
            </MapContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        community: state.activeCommunity
    }
}

export default connect(mapStateToProps)(CommunityMap);
