import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

class FireStation extends Component {

    state = {
        fire_stations: [],
        filtered_fire_station: [],
        comm_code: ''
    }

    async componentDidMount() {
        const apiUrl = "https://data.calgary.ca/resource/cqsb-2hhg.json";
        await axios.get(apiUrl)
            .then(res => {

                const fire_stations = res.data.map(fire_station => {
                        return {
                            id: fire_station.globalid,
                            name: fire_station.name,
                            address: fire_station.address,
                            stn_num: fire_station.stn_num,
                            community_name: fire_station.community,
                            non_emergency_info: fire_station.non_emergency_info,
                            map_url: "https://www.google.com/maps/?q="
                                + fire_station.community
                                + '+' + fire_station.name
                                + '&ll='
                                + fire_station.point.coordinates[1]
                                + ","
                                + fire_station.point.coordinates[0]
                                + '&z=18'
                            ,
                        } ;
                    }
                );

                this.setState({ fire_stations });

            }).catch(function (error) {
            console.error(error);
        });
    }

    componentDidUpdate() {
        if ( (this.props.community !== null) && this.comm_code !== this.props.community.comm_code) {
            this.filterFireStationByCommunityName();
        }
    }

    filterFireStationByCommunityName() {

        if( this.props.community !== null ) {
            this.comm_code = this.props.community.comm_code;
            const activeCommunity = {...this.props.community};
            const filtered_fire_station = this.state.fire_stations.filter((fire_station) => fire_station.community_name.toUpperCase() === activeCommunity.name);
            this.setState({ filtered_fire_station });
        }

    }

    render() {

        if(this.state.filtered_fire_station.length <= 0) {
            return (
                <div />
            )
        }

        return(
            <div className="max-w-4xl flex items-center flex-wrap mx-auto overflow-hidden rounded-xl m-4">

                <div className="w-full rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl opacity-45 mx-6 lg:mx-0 bg-red-200">
                    <div className="flex-auto p-6">
                        <div className="flex flex-wrap">
                            <label
                                className="w-8 h-8 rounded-full shadow-sm bg-gradient-to-r red-700 to-red-400 shadow-md text-center-2 flex items-center justify-center bg-none bg-red-700">

                                <svg aria-hidden="true" data-prefix="fas" data-icon="bullhorn"
                                     className="w-5 h-5 stroke-current text-red-200" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="currentColor"
                                          d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"/>
                                </svg>
                            </label>

                            <h1 className="text-red-600 mx-2 font-bold flex-auto text-xl font-semibold">
                                Fire Station
                            </h1>
                        </div>

                        <div className="grid grid-cols-1 gap-8 mt-4">
                            { this.state.filtered_fire_station.map((fire_station) => (

                                <div className="row" key={fire_station.id}>
                                    <div className="w-full flex-none text-sm font-bold text-gray-700 mt-4 text-xl">
                                        { fire_station.name }
                                    </div>
                                    <div className="mt-0 text-sm text-gray-600">
                                        The Calgary Fire Department has 41 fire stations across the city which Calgary firefighters operate 24 hours a day.<br/> <a className="font-bold text-red-500" rel="noreferrer" href={fire_station.non_emergency_info} target="_blank">Click here</a> for more information.
                                    </div>

                                    <div className="mt-0 flex cursor-pointer mt-4 text-red-600 align-middle">
                                        <svg aria-hidden="true" data-prefix="fas" data-icon="map-pin"
                                             className="w-4 h-4" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512">
                                            <path fill="currentColor"
                                                  d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z" />
                                        </svg>

                                        <div className="pl-1 text-sm text-white text-l">
                                            <a className="text-gray-700" rel="noreferrer" href={fire_station.map_url} target="_blank">{ fire_station.stn_num } - { fire_station.address }</a>
                                        </div>


                                    </div>
                                </div>


                            ))}

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        community: state.activeCommunity
    }
}

export default connect(mapStateToProps)(FireStation);