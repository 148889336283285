import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectCommunity } from '../actions/selectCommunity';
import axios from 'axios';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import slugify from 'slugify';

class Hero extends Component {
  state = {
    communities: [],
    isInitialLoad: true
  }

  async componentDidMount() {
    const apiUrl = "https://data.calgary.ca/resource/kzbm-mn66.json";
    try {
      const url_prefix = '/calgary/community/';
      const res = await axios.get(apiUrl);
      const data = res.data.map(community => ({
        key: community.comm_code,
        label: community.name,
        value: community.name,
        comm_code: community.comm_code,
        name: community.name,
        sector: community.sector,
        class: community.class,
        longitude: community.longitude,
        latitude: community.latitude,
        location: community.location,
        map_url: "https://www.google.com/maps/?q=" +
          community.name +
          '&ll=' +
          community.latitude +
          "," +
          community.longitude +
          '&z=13',
        url: url_prefix + slugify(community.name, { lower: true }),
      }));

      const communities = data.filter(o => o.class !== 'Residual Sub Area');
      communities.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      this.setState({ communities });

      const searchSlug = url_prefix + this.props.match.params.communityName;

      if (this.props.match.params.communityName) {
        const community = communities.find(o => o.url === searchSlug);

        if (community) {
          this.props.selectCommunity(community);
        }
      } else {
        const defaultCommunity = communities.find(o => o.label === 'DOWNTOWN COMMERCIAL CORE');

        if (defaultCommunity) {
          this.props.selectCommunity(defaultCommunity);
        }
      }
    } catch (error) {
      console.error("Error fetching community data:", error);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.isInitialLoad && this.props.community !== prevProps.community) {
      this.setState({ isInitialLoad: false });
      this.props.history.push(this.props.community.url);
    }
  }

  handleCommunityChange = (community) => {
    this.props.selectCommunity(community);
    this.props.history.push(community.url);
  }

  render() {
    const customStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "#ebf6df",
        padding: 7,
        borderRadius: 4
      })
    };

    return (
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <a href='/'><img src='/assets/logo-calgary-communities-yyc.png' alt='logo-calgary-yyc-communities' className='mx-auto max-w-[400px] lg:w-1/2 lg:w-auto' /></a>
            <p className="mt-10 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl mx-auto">
              A better way to find information about your community in Calgary
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
              The data is provided by the City of Calgary’s Open Data Portal.
            </p>
            <p className="max-w-2xl text-sm text-gray-400 mx-auto">
              All data is subject to change as it is updated.
            </p>
          </div>
          <div className="mt-10 max-w-2xl mx-auto">
            <Select
              closeMenuOnSelect={true}
              options={this.state.communities}
              onChange={this.handleCommunityChange}
              value={this.props.community}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#ebf6df',
                  primary: '#90b197',
                },
              })}
              styles={customStyles}
              placeholder="Search by Community..."
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    community: state.activeCommunity
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    { selectCommunity: selectCommunity },
    dispatch
  )
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(Hero));
