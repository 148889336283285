import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

class Service extends Component {

    state = {
        services: [],
        comm_code: ''
    }

    componentDidUpdate() {
        if ( (this.props.community !== null) && this.comm_code !== this.props.community.comm_code) {
            this.getServicesByCommunityCode();
        }
    }

    async getServicesByCommunityCode() {

        if( this.props.community !== null ) {
            this.comm_code = this.props.community.comm_code;

            const apiUrl = "https://data.calgary.ca/resource/x34e-bcjz.json?comm_code=" + this.props.community.comm_code;
            await axios.get(apiUrl)
                .then(res => {

                    const services = res.data.map((service, index) => {
                            return {
                                id: index,
                                type: service.type,
                                name: service.name,
                                address: service.address,
                                longitude: service.longitude,
                                latitude: service.latitude,
                                map_url: "https://www.google.com/maps/?q="
                                    + this.props.community.name
                                    + '+' + service.name
                                    + '&ll='
                                    + service.latitude
                                    + ","
                                    + service.longitude
                                    + '&z=18'
                            };
                        }
                    );
                    services.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    this.setState({services});

                }).catch(function (error) {
                console.error(error);
            });

        }

    }

    render() {
        if(this.state.services.length <= 0) {
            return (
                <div />
            )
        }

        return(
            <div className="max-w-4xl flex items-center flex-wrap mx-auto overflow-hidden rounded-xl m-4">
                <div className="w-full rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl mx-6 lg:mx-0 bg-green-200">
                    <div className="flex-auto p-6">
                        <div className="flex flex-wrap">
                            <label
                                className="w-8 h-8 rounded-full shadow-sm bg-gradient-to-r from-green-700 to-green-400 shadow-md text-center-2 flex items-center justify-center bg-none bg-green-700">

                                <svg aria-hidden="true" data-prefix="fas" data-icon="bullhorn"
                                     className="w-5 h-5 stroke-current text-green-200" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="currentColor"
                                          d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"/>
                                </svg>
                            </label>

                            <h1 className="text-green-700 mx-2 font-bold flex-auto text-xl font-semibold">
                                Services
                            </h1>
                        </div>

                        <div className="gap-8 mt-4">

                            { this.state.services.map((service) => (

                                <div className="row" key={service.id}>
                                    <div className="w-full flex-none text-sm font-bold text-green mt-5 mb-2 text-xl border-b-2 border-green-300">
                                        { service.name }
                                    </div>
                                    <div className="mt-0 text-sm text-gray-800">
                                        Type: { service.type }
                                    </div>

                                    <div className="mt-0 flex cursor-pointer mt-4 text-green align-middle">
                                        <svg aria-hidden="true" data-prefix="fas" data-icon="map-pin"
                                             className="w-4 h-4" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512">
                                            <path fill="currentColor"
                                                  d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z" />
                                        </svg>

                                        <div className="pl-1 text-sm text-white text-l">
                                            <a className="font-bold text-green-500" rel="noreferrer" href={service.map_url} target="_blank">{ service.address }</a>
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>

                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        community: state.activeCommunity
    }
}

export default connect(mapStateToProps)(Service);