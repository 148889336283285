import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Hero from './components/Hero';
import Crime from './components/Crime';
import Service from './components/Service';
import FireStation from './components/FireStation';
import CommunityCard from './components/CommunityCard';
import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/calgary/community/:communityName?" component={Main} />
        <Route path="/" component={Main} />
      </Switch>
    </Router>
  );
}

const Main = () => (
  <>
    <Hero />
    <CommunityCard />
    <FireStation />
    <Service />
    <Crime />
  </>
);

export default App;
