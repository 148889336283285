import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { months } from '../libs/constants';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

class Crime extends Component {

    state = {
        crimes: [],
        filtered_crimes: [],
        comm_code: ''
    }

    async componentDidMount(){
        const apiUrl = "https://data.calgary.ca/resource/78gh-n26t.json?$limit=50000&year=2024";
        await axios.get(apiUrl)
            .then(res => {

                const crimes = res.data.map((crime, index) => {
                        return {
                            id: `${crime.community}-${crime.year}-${crime.month}-${index}`,
                            community: crime.community,
                            sector: crime.sector,
                            category: crime.category,
                            count: crime.crime_count,
                            year: crime.year,
                            month: crime.month,
                        } ;
                    }
                );

                crimes.sort((a,b) => (a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0));
                crimes.sort((a,b) => (a.month > b.month) ? 1 : ((b.month > a.month) ? -1 : 0));
                this.setState({ crimes });
                this.filterCrimeStatisticsByCommunity();
            }).catch(function (error) {
                console.error(error);
            });
    }

    componentDidUpdate() {
        if ( (this.props.community !== null) && this.comm_code !== this.props.community.comm_code) {
            this.filterCrimeStatisticsByCommunity();
        }
    }

    filterCrimeStatisticsByCommunity() {
        if( this.props.community !== null && this.state.crimes.length > 0 ) {
            this.comm_code = this.props.community.comm_code;
            const activeCommunity = {...this.props.community};
            const filtered_crimes = this.state.crimes.filter((crime) => {
                return crime.community && activeCommunity.name && crime.community.toUpperCase() === activeCommunity.name.toUpperCase();
              });
            this.setState({ filtered_crimes });
        }
    }

    getCrimeDataForChart(crimes) {
        const totalCrimesByCategory = new Map();
        const colors = {};

        const predefinedColors = [
            'rgba(255, 99, 132, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 0, 0, 0.8)',
            'rgba(0, 255, 0, 0.8)',
            'rgba(0, 0, 255, 0.8)',
            'rgba(255, 255, 0, 0.8)',
            'rgba(255, 0, 255, 0.8)',
            'rgba(0, 255, 255, 0.8)',
            'rgba(128, 0, 128, 0.8)',
            'rgba(128, 128, 128, 0.8)',
            'rgba(0, 128, 0, 0.8)'
        ];

        let colorIndex = 0;

        crimes.forEach(crime => {
            const monthIndex = crime.month - 1;
            const crimeCount = Number(crime.count);

            if (!totalCrimesByCategory.has(crime.category)) {
                totalCrimesByCategory.set(crime.category, Array(12).fill(0));

                colors[crime.category] = predefinedColors[colorIndex];
                colorIndex = (colorIndex + 1) % predefinedColors.length;
            }
            totalCrimesByCategory.get(crime.category)[monthIndex] += crimeCount;
        });

        const datasets = [];

        for (const [category, crimeData] of totalCrimesByCategory.entries()) {
            datasets.push({
                label: category,
                backgroundColor: colors[category],
                borderWidth: 0,
                data: crimeData
            });
        }

        return {
            labels: months,
            datasets: datasets
        };
    }

    render() {
        if(this.state.filtered_crimes.length <= 0) {
            return (
                <div />
            )
        }
        const chartData = this.getCrimeDataForChart(this.state.filtered_crimes);

        return(
            <div className="max-w-4xl flex items-center flex-wrap mx-auto overflow-hidden rounded-xl m-4">

                <div className="w-full rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl opacity-45 mx-6 lg:mx-0 bg-indigo-50">
                    <div className="flex-auto p-6">
                        <div className="flex flex-wrap">
                            <label
                                className="w-8 h-8 rounded-full shadow-sm bg-gradient-to-r from-blue-700 to-blue-400 shadow-md text-center-2 flex items-center justify-center bg-none bg-blue-700">

                                <svg aria-hidden="true" data-prefix="fas" data-icon="bullhorn"
                                     className="w-5 h-5 stroke-current text-blue-200" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="currentColor"
                                          d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"/>
                                </svg>
                            </label>

                            <h1 className="text-blue-700 mx-2 font-bold flex-auto text-xl font-semibold">
                                Crime Statistics
                            </h1>
                        </div>

                        <div className="my-8 p-4">
                            <Bar
                                data={chartData}
                                options={{
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: 'Crime Statistics by Month',
                                        },
                                        legend: {
                                            display: true,
                                            position: 'bottom'
                                        }
                                    },
                                    scales: {
                                        x: {
                                            type: 'category',
                                            stacked: false,
                                        },
                                        y: {
                                            beginAtZero: true,
                                            stacked: false,
                                        }
                                    },

                                }}
                            />
                        </div>

                        <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg mt-5">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Category
                                    </th>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                                        Incidents
                                    </th>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center hidden sm:block">
                                        Date
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    { this.state.filtered_crimes.map((crime) => (
                                        <tr key={crime.id}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">{ crime.category }</div>
                                                <div className="text-sm text-blue-900 block sm:hidden">{months[crime.month - 1]} - { crime.year }</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-center">
                                                <div className="text-sm text-gray-900">{ crime.count }</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-center hidden sm:block">
                                                <div className="text-sm text-gray-900">{months[crime.month - 1]} - { crime.year }</div>
                                            </td>
                                        </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        community: state.activeCommunity
    }
}

export default connect(mapStateToProps)(Crime);
